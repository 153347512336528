// @codekit-prepend "jquery-migrate-1.4.1.min.js" quiet;
// @codekit-prepend "jquery-migrate-3.1.0.min.js" quiet;

// @codekit-prepend "isotope.pkgd.js" quiet;
// @codekit-prepend "packery-mode.pkgd.js" quiet;

// @codekit-prepend "masonry.pkgd.min.js" quiet;
// @codekit-prepend "imagesloaded.js" quiet;

// @codekit-prepend "jquery.cycle2.js" quiet;
// @codekit-prepend "jquery.cycle2.autoheight.js" quiet;
// @codekit-prepend "jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "jquery.cycle2.center.js" quiet;
// @codekit-prepend "jquery.easing.1.3.js" quiet;


$.noConflict();
jQuery(document).ready(function($) {


	$('.archive-events .event-item .description').hide();

	$('.archive-events .event-item .description-wrapper > .button-wrapper > button').click(function() {
		$(this).hide(50);
		$(this).parent().next().delay(50).slideDown(600);
	});

	$('.archive-events .event-item .description .button-wrapper button').click(function() {
		$(this).parent().parent().slideUp(600);
		$(this).parent().parent().prev().find('button').delay(600).show(50);
	});



	var $grid = $('.grid').isotope({
		itemSelector: '.grid-item',
		layoutMode: 'packery',
		sortBy: 'original-order',
		percentPosition: true,
		packery: {
			//columnWidth: '.grid-sizer',
			columnWidth: 1,
			gutter: 0
		},
		// masonry: {
		// 	columnWidth: 1,
		// 	horizontalOrder: true,
		// 	fitWidth: false,
		// }
	});

	// refresh layout Isotope after each image is loaded
	$grid.imagesLoaded().progress(function() {
		$grid.isotope('layout');
	});

	// refresh layout Isotope after all images are loaded
	$('#gallery').imagesLoaded(function() {
		$grid.delay(100).isotope('layout');
	});


	// function to get the lightbox content with ajx and open it in a cycle2 slider	 
	function ajax_lightbox(post_id, startingSlide) {

		$.ajax({
			type: "POST",
			url: mw_ajax_object.ajax_url,
			data: {
				action: 'ajax_lightbox',
				post_id: post_id,
			}
		}).done(function(data) {


			// Just simple html() method with data will show all the content.
			$('main').after(data);
			$('#lightbox-content').find('img').each(function() {

				$(this).hide();
				var img_src = $(this).attr('data-src');
				var img_src_set = $(this).attr('data-srcset');
				$(this).load().attr("src", img_src).attr("srcset", img_src_set).fadeIn(300, function() { $(this).parent().removeClass('loading'); });
			});

			$('#lightbox-content').show();
			$('body').addClass('fixed');

			$('#lightbox-content .lightbox-slider').cycle({
				'slides': '.lightbox-item',
				'cycleFx': 'scrollHorz',
				'timeout': 0,
				'autoHeight': 'calc',
				'startingSlide': startingSlide,
				'swipe': true,
				'manualSpeed': 400,
				'manualFx': 'scrollHorz',
				'pagerTemplate': '<span></span>'
			});

		});

	}
	// end lightbox function ----------------------------------------------------



	// open the lightbox by click an image in an image group element
	$('.grid img').on('click', function(e) {
		console.log('img click');

		var post_id = $('#gallery').attr('data-post_id');
		var startingSlide = $(this).closest('figure').index(); // get the ide of the clicked image as value for the start point of the slider 

		console.log('post_id:' + post_id + ' / startingSlide:' + startingSlide);
		ajax_lightbox(post_id, startingSlide);

	});


	// close the lightbox with close button
	$(document).on('click', '.lightbox-close', function() {
		$('#lightbox-content').fadeOut(100, function() {
			$('body').removeClass('fixed');
			$('#lightbox-content').remove();
		});
	});

	// close the lightbox with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			$('#lightbox-content').fadeOut(100, function() {
				$('body').removeClass('fixed');
				$('#lightbox-content').remove();
			});
		}

		if (evt.keyCode == 37) {
			$('#lightbox-content .lightbox-slider').cycle('next');
		}
		if (evt.keyCode == 38) {
			$('#lightbox-content .lightbox-slider').cycle('next');
		}
		if (evt.keyCode == 39) {
			$('#lightbox-content .lightbox-slider').cycle('prev');
		}
		if (evt.keyCode == 40) {
			$('#lightbox-content .lightbox-slider').cycle('prev');
		}
	});


	// burger nav --------------------------------------------------------------------------------------------------------------

	$(document).on('click', 'header .hamburger', function() {
		$('body').toggleClass('bodyfix');
		$(this).toggleClass('is-active');
		$('header nav').slideToggle();
		$('header').toggleClass('burger-menu-is-active').toggleClass('burger-menu-not-active');
	});


	// -------------------------------------------------------------------------------------

	// Disable right click on images
	// $(document).on('contextmenu', 'img', function() {
	// 	return false;
	// });
	// -------------------------------------------------------------------------------------  


	// -------------------------------------------------------------------------------------  
	// smoth scroll to top
	$('a[href^="#"]').on('click', function(e) {
		e.preventDefault();
		var target = this.hash;
		var $target = $(target);
		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 900, 'swing', function() {
			window.location.hash = target;
		});
	});
	// ------------------------------------------------------------------------------------- 

});